"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderItemStatus = void 0;
var OrderItemStatus;
(function (OrderItemStatus) {
    OrderItemStatus["READY"] = "READY";
    OrderItemStatus["PRINTING"] = "PRINTING";
    OrderItemStatus["PRINTED"] = "PRINTED";
    OrderItemStatus["SHIPPED"] = "SHIPPED";
})(OrderItemStatus || (exports.OrderItemStatus = OrderItemStatus = {}));
