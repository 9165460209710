import { createContext, useState, useCallback, useEffect, useMemo } from 'react';
import { v4 } from 'uuid';
import Cookies from 'js-cookie';
import { Cart } from 'canvas-common';
import API from '../API';
import { CartUpdateBody } from 'canvas-common';

export type UserDataContext = {
  cart: Cart;
  userToken: string | undefined;
  authToken: string | undefined;
  refreshToken: string | undefined;
  syncTokens: (uToken: string, aToken: string, rToken: string) => void;
  clearCart: () => Promise<Cart>;
  syncCartItems: (update: CartUpdateBody) => Promise<Cart>;
  isLoggedIn: boolean;
};

export const UserData = createContext<UserDataContext | null>(null);

const UserDataProvider = ({ children }: any) => {
  const [userToken, setUserToken] = useState<string | undefined>(undefined);
  const [authToken, setAuthToken] = useState<string | undefined>(undefined);
  const [refreshToken, setRefreshToken] = useState<string | undefined>(undefined);
  const [cart, setCart] = useState<Cart>({ cartItems: [] });

  const apiClient = API(undefined);

  const syncTokens = useCallback((uToken: string, aToken: string, rToken: string) => {
    setUserToken(uToken);
    Cookies.set('userToken', uToken as string);
    setAuthToken(aToken);
    Cookies.set('authToken', aToken as string);
    setRefreshToken(rToken);
    Cookies.set('refreshToken', rToken as string);
  }, []);

  useEffect(() => {
    const intializeCart = async () => {
      let localUser = Cookies.get('userUUID');
      if (!localUser) {
        localUser = v4();
        Cookies.set('userUUID', localUser as string);
        setCart({ userUUID: localUser, cartItems: [] });
      } else {
        const newCart = await apiClient.getAnonCart(localUser);
        setCart(newCart);
      }
    };

    intializeCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const intializeTokens = async () => {
      setUserToken(Cookies.get('userToken'));
      setAuthToken(Cookies.get('authToken'));
      setRefreshToken(Cookies.get('refreshToken'));
    };

    intializeTokens();
  }, []);

  const updateCart = async (update: CartUpdateBody) => {
    const newCart = await apiClient.updateCart(update);
    setCart(newCart);
    return newCart;
  };

  const clearCart = async () => {
    await apiClient.deleteCart(cart.userUUID as string);
    const localUser = v4();
    const newCart = { userUUID: localUser, cartItems: [], email: cart.email };
    Cookies.set('userUUID', localUser as string);
    setCart(newCart);
    return newCart;
  };

  const syncCartItems = useCallback((update: CartUpdateBody) => {
    return updateCart(update);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoggedIn = useMemo(() => userToken !== null, [userToken]);

  const value = {
    userToken,
    authToken,
    refreshToken,
    cart,
    syncTokens,
    clearCart,
    syncCartItems,
    isLoggedIn,
  };

  return <UserData.Provider value={value}>{children}</UserData.Provider>;
};

export default UserDataProvider;
