import { createContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

type UserContextDefaultValue = {
  permissions: any[];
  userInitialized: boolean;
};

export const UserContext = createContext<UserContextDefaultValue>({
  permissions: [],
  userInitialized: false,
});

export const UserContextProvider = ({ children }: any) => {
  const { user, isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [permissions, setPermissions] = useState<any[]>([]);
  const [userInitialized, setUserIntialized] = useState(false);

  useEffect(() => {
    const loadUser = async () => {
      const accessToken = await getAccessTokenSilently();
      const decoded: any = jwt_decode(accessToken);
      const perms = decoded.permissions;
      setPermissions(perms);
      setUserIntialized(true);
    };

    if (!userInitialized) {
      if (!isLoading) {
        if (isAuthenticated) {
          loadUser();
        } else {
          setUserIntialized(true);
        }
      }
    }
  }, [userInitialized, isAuthenticated, isLoading, getAccessTokenSilently, user]);

  const value = {
    permissions,
    userInitialized,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
