import { Helmet } from 'react-helmet';
import { DateTime } from 'luxon';
import { Template } from 'canvas-common';

interface TemplateStructuredDataProps {
  template: Template;
}

export const TemplateStructuredData = ({ template }: TemplateStructuredDataProps) => {
  const expireDate = DateTime.now().plus({ days: 7 }).toFormat('yyyy-MM-dd');
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org/',
          '@type': 'Product',
          name: `${template.title}`,
          image: [`${template.url}`],
          brand: {
            '@type': 'Brand',
            name: 'CanvasPaints',
          },
          sku: `${template.templateId}`,
          description: `Personalized 24x60 hanging fabric banner. Sport: ${template.sport} Team: ${template.team}`,
          offers: {
            '@type': 'Offer',
            price: 89.99,
            priceCurrency: 'USD',
            priceValidUntil: `${expireDate}`,
            availability: 'InStock',
          },
        })}
      </script>
    </Helmet>
  );
};
