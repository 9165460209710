import { useContext, useState } from 'react';
import {
  AppBar,
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { BadgeProps } from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { UserData, UserDataContext } from '../context/UserData';

const pages = ['Teams', 'Product', 'About'];
const locations = [
  { path: '/', index: 0 },
  { path: '/teams', index: 0 },
  { path: '/product', index: 1 },
  { path: '/about', index: 2 },
];

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const location = useLocation();

  let menuIndex: number | undefined | false = locations.find(
    (loc) => loc.path === location.pathname
  )?.index;
  if (menuIndex === undefined) menuIndex = false;

  const [currentMenu, setCurrentMenu] = useState<number | false>(menuIndex);
  const { cart } = useContext(UserData) as UserDataContext;

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navigate = useNavigate();

  const BasicTabs = () => {
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setCurrentMenu(newValue);
    };

    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderColor: 'divider' }}>
          <Tabs value={currentMenu} onChange={handleChange} aria-label="menu tabs">
            {pages.map((page, index) => {
              const theRef = `/${page.toLowerCase()}`;
              return (
                <Tab key={index} label={page} component={Link} to={theRef} {...a11yProps(index)} />
              );
            })}
          </Tabs>
        </Box>
      </Box>
    );
  };

  return (
    <AppBar position="sticky" color="inherit" sx={{ zIndex: '10' }}>
      <Box ml={3}>
        <Toolbar disableGutters>
          <Box
            component="img"
            mr={5}
            sx={{
              height: 38,
              width: 67,
              display: { xs: 'none', md: 'block' },
            }}
            alt="CanvasPaints"
            src="logo.png"
          />

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page, index) => (
                <MenuItem
                  key={page}
                  onClick={() => {
                    navigate(`/${page.toLowerCase()}`);
                    setCurrentMenu(index);
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            component="img"
            sx={{
              height: 38,
              width: 67,
              flexJustify: 'space-between',
              display: { xs: 'flex', md: 'none' },
            }}
            alt="CanvasPaints"
            src="logo.png"
          />
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} />
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <BasicTabs />
          </Box>

          <Box mr={3} sx={{ flexGrow: 0 }}>
            <Tooltip title="Show Cart">
              <IconButton
                aria-label="cart"
                onClick={() => {
                  setCurrentMenu(false);
                  navigate('/cart?step=0');
                }}
                sx={{ marginRight: '20px' }}
              >
                <StyledBadge badgeContent={cart.cartItems.length} color="secondary">
                  <ShoppingCartIcon />
                </StyledBadge>
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};
