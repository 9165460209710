import { Box, Button, Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import { CloseButton } from '../basic/CloseButton';
import { useNavigate } from 'react-router-dom';
import { Product, Template } from 'canvas-common';
import currency from 'currency.js';

interface TemplateCardProps {
  template: Template;
  product: Product;
  setPersonalized: (personalized: boolean) => void;
}

export const TemplateCard = ({ template, product, setPersonalized }: TemplateCardProps) => {
  const navigate = useNavigate();

  return (
    <Card key={template.templateId} sx={{ minWidth: '520px' }}>
      <CloseButton onClose={() => navigate(-1)} />
      <CardContent style={{ justifyContent: 'left', display: 'flex' }} sx={{ mr: 6 }}>
        <Stack direction={'row'}>
          <Box ml={4}>
            <img width={200} height={500} src={template.url} alt={template.title} />
          </Box>
          <Stack ml={5}>
            <Box mb={3}>
              <Typography variant={'h5'} align="left" color="textSecondary">
                Template
              </Typography>
              <Typography fontSize={20} align="left" color="textSecondary">
                {template.title}
              </Typography>
              <Typography fontSize={18} align="left" color="textSecondary">
                Sport: <b>{template.sport}</b>
              </Typography>
              <Typography fontSize={18} align="left" color="textSecondary">
                Team: <b>{template.team}</b>
              </Typography>
              <Typography fontSize={18} align="left" color="textSecondary" gutterBottom>
                Number of Photos: {template.images.length}
              </Typography>
            </Box>
            <Divider />

            <Box mt={3}>
              <Typography variant={'h5'} align="left" color="textSecondary">
                Product
              </Typography>
              <Typography fontSize={20} align="left" color="textSecondary">
                {product.name}
              </Typography>
              <Typography fontSize={18} align="left" color="textSecondary">
                {product.description}
              </Typography>
              <Typography fontSize={18} align="left" color="textSecondary">
                Price: {currency(product.price).format()}
              </Typography>
            </Box>
            <Button
              onClick={() => setPersonalized(true)}
              variant="contained"
              sx={{ width: 200, mt: 4, ml: 1, mr: 1 }}
              aria-label="Personalize this banner template"
            >
              Personalize
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
