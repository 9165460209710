import { Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { ProductView } from './views/ProductView';
import { AboutView } from './views/AboutView';
import { CreateView } from './views/CreateView';
import { LoginView } from './views/LoginView';
import { CartView } from './views/CartView';
import { OrderView } from './views/OrderView';
import { TemplateView } from './views/TemplateView';
import UserDataProvider from './context/UserData';
import ProductDataProvider from './context/ProductData';
import TemplateDataProvider from './context/TemplateData';
import SnackbarContextProvider from './context/SnackbarContext';
import { PrivateRoute } from './components/basic/PrivateRoute';
import { AuthProvider } from './context/AuthProvider';
import { UserContextProvider } from './context/UserContext';

const theme = createTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: { xs: 0, sm: 680, md: 850, lg: 1200, xl: 1800 },
  },
});

export const App = () => {
  return (
    <AuthProvider>
      <UserContextProvider>
        <ProductDataProvider>
          <TemplateDataProvider>
            <UserDataProvider>
              <ThemeProvider theme={theme}>
                <SnackbarContextProvider>
                  <div style={{ paddingBottom: '80px', textAlign: 'center' }}>
                    <Header />
                    <div style={{ height: '20px' }} />
                    <Routes>
                      <Route path="/teams" element={<CreateView />} />
                      <Route path="/product" element={<ProductView />} />
                      <Route path="/create" element={<CreateView />} />
                      <Route path="/about" element={<AboutView />} />
                      <Route path="/login" element={<PrivateRoute component={LoginView} />} />
                      <Route path="/cart" element={<CartView />} />
                      <Route path="/order" element={<OrderView />} />
                      <Route path="/template/:templateId" element={<TemplateView />} />
                      <Route path="/" element={<Navigate to="/teams" replace />}></Route>
                    </Routes>
                    <Footer />
                  </div>
                </SnackbarContextProvider>
              </ThemeProvider>
            </UserDataProvider>
          </TemplateDataProvider>
        </ProductDataProvider>
      </UserContextProvider>
    </AuthProvider>
  );
};
