import { Alert, AlertColor, Snackbar } from '@mui/material';
import { createContext } from 'react';
import * as React from 'react';

export class Snack {
  message?: string;
  color?: AlertColor;
  autoHideDuration?: number;
  open: boolean;

  constructor(data: Snack) {
    this.message = data.message || '';
    this.color = data.color || 'info';
    this.autoHideDuration = data.autoHideDuration || 4000;
    this.open = data.open;
  }
}

type SnackDefaultValue = {
  snack: Snack;
  setSnack: React.Dispatch<React.SetStateAction<Snack>>;
};

export const SnackbarContext = createContext<SnackDefaultValue>({
  snack: new Snack({ open: false }),
  setSnack: () => {},
});

const SnackbarContextProvider = ({ children }: any) => {
  const [snack, setSnack] = React.useState(new Snack({ open: false }));

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnack(new Snack({ color: snack.color, open: false }));
  };

  return (
    <SnackbarContext.Provider value={{ snack, setSnack }}>
      <Snackbar
        open={snack.open}
        autoHideDuration={snack.autoHideDuration}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
      >
        <Alert severity={snack.color} sx={{ width: '100%' }}>
          {snack.message || ''}
        </Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarContextProvider;
