import { CircularProgress } from '@mui/material';

export const CenteredProgress = () => {
  return (
    <div style={{ position: 'relative', height: '100vh' }}>
      <div
        style={{
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: `translate(-50%, -50%)`,
        }}
      >
        <CircularProgress />
      </div>
    </div>
  );
};
