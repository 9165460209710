import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import currency from 'currency.js';
import { DateTime } from 'luxon';
import * as EmailValidator from 'email-validator';
import { useNavigate } from 'react-router-dom';
import { CartItem } from 'canvas-common';
import { Order } from 'canvas-common/classes/Order';
import API from '../../API';
import { CenteredProgress } from '../basic/CenteredProgress';
import { Snack, SnackbarContext } from '../../context/SnackbarContext';

const displayStatuses = ['In Process', 'Ready To Print', 'Printed', 'Shipped'];

interface OrderViewProps {
  orders: Order[];
}
export const OrderDisplay = ({ orders }: OrderViewProps) => {
  const [itemUrls, setItemUrls] = useState<Map<string, string>>(new Map());
  const [validEmail, setValidEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [busy, setBusy] = useState(false);
  const navigate = useNavigate();

  const { setSnack } = useContext(SnackbarContext);

  const apiClient = API(undefined);

  useEffect(() => {
    const renderCartItems = async () => {
      Promise.all(
        orders.map(async (order: Order) => {
          if (displayStatuses.includes(order.status)) {
            order.items.map(async (ci: CartItem) => {
              const body = {
                templateId: ci.personalizedTemplate.template.templateId,
                images: ci.personalizedTemplate.images,
              };
              const data = await apiClient.renderTemplate(body);
              setItemUrls(new Map(itemUrls.set(ci.id, data)));
            });
          }
        })
      );
    };

    renderCartItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTrackPackage = () => {
    console.log('NEED SHIPMENT TRACKING');
  };

  const handleSendLink = async () => {
    setBusy(true);
    try {
      await apiClient.createOrderLink(email);
      setBusy(false);
      setSnack(
        new Snack({
          message: `Email sent to ${email}. Please check your email for the order link`,
          color: 'success',
          open: true,
        })
      );
      navigate('/home');
    } catch (err) {
      setSnack(
        new Snack({
          message: 'Error sending the link. Please try again',
          color: 'error',
          open: true,
        })
      );
      setBusy(false);
    }
  };

  const handleEmailChange = (event: any) => {
    setValidEmail(EmailValidator.validate(event.target.value));
    setEmail(event.target.value);
  };

  if (busy) {
    return <CenteredProgress />;
  }

  return (
    <>
      <Paper elevation={4} sx={{ margin: '20px', padding: '20px' }}>
        <Box sx={{ width: '100%' }}>
          <Box>
            <Typography mt="20px" ml="12px" mb="12px" variant="h5" align="left">
              Your Orders
            </Typography>
          </Box>

          {orders.length === 0 && (
            <Card variant="outlined" sx={{ borderRadius: '10px', margin: '10px' }}>
              <Typography variant="h6" align="left" ml="12px" mt="20px">
                Link Expired
              </Typography>
              <Typography align="left" ml="12px" mt="20px">
                Looks like your order link has expired. Enter your email address and we will send
                you a new link.
              </Typography>

              <Box mt="12px">
                <TextField
                  id="outlined-basic"
                  label="email"
                  variant="outlined"
                  onChange={handleEmailChange}
                  sx={{ width: '95%', margin: 'auto' }}
                />
              </Box>
              <Button
                disabled={!validEmail}
                onClick={handleSendLink}
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
              >
                Send New Link
              </Button>
            </Card>
          )}

          {orders.length > 0 &&
            orders.map((order) => {
              return (
                <>
                  <Card
                    variant="outlined"
                    sx={{ borderRadius: '10px', mt: '20px', ml: '10px', mb: '10px', mr: '10px' }}
                  >
                    <Box key={order.orderNumber} sx={{ backgroundColor: '#dddddd' }}>
                      <Grid container spacing={1} sx={{ ml: 2, pt: 2, pb: 2 }}>
                        <Grid xs={3}>
                          <Typography
                            ml="12px"
                            align="left"
                            sx={{ textTransform: 'uppercase', fontSize: 12 }}
                          >
                            Order Placed
                          </Typography>
                          <Typography ml="12px" align="left" sx={{ fontSize: 12 }}>
                            {DateTime.fromISO(order.createdAt).toFormat('MM/dd/yyyy')}
                          </Typography>
                        </Grid>
                        <Grid xs={3}>
                          <Typography
                            ml="12px"
                            align="left"
                            sx={{ textTransform: 'uppercase', fontSize: 12 }}
                          >
                            Total
                          </Typography>
                          <Typography ml="12px" align="left" sx={{ fontSize: 12 }}>
                            {currency(order.totalCost).format()}
                          </Typography>
                        </Grid>
                        <Grid xs={3}>
                          <Typography
                            ml="12px"
                            align="left"
                            sx={{ textTransform: 'uppercase', fontSize: 12 }}
                          >
                            Ship To
                          </Typography>
                          <Typography ml="12px" align="left" sx={{ fontSize: 12 }}>
                            {order.shippingAddress.name}
                          </Typography>
                        </Grid>
                        <Grid xs={3}>
                          <Typography
                            ml="12px"
                            align="left"
                            sx={{ textTransform: 'uppercase', fontSize: 12 }}
                          >
                            Order #
                          </Typography>
                          <Typography ml="12px" align="left" sx={{ fontSize: 12 }}>
                            {order.orderNumber}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <div style={{ display: 'flex' }}>
                        <Typography ml="12px">Order Status:</Typography>
                        <Typography ml="12px" fontWeight="700">
                          {order.status.toUpperCase()}
                        </Typography>
                      </div>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Box mr="12px">
                        <Tooltip
                          title={
                            order.status === 'Shipped'
                              ? 'Track shipment'
                              : 'Tracking availabe after order ships'
                          }
                        >
                          <span>
                            <Button
                              disabled={!(order.status === 'Shipped')}
                              onClick={handleTrackPackage}
                            >
                              Track Package
                            </Button>
                          </span>
                        </Tooltip>
                      </Box>
                    </Box>

                    {displayStatuses.includes(order.status) &&
                      order.items.map((cartItem: any) => {
                        return (
                          <Box key={cartItem.id}>
                            <Card variant="outlined" sx={{ margin: '10px' }}>
                              <CardContent>
                                <Stack direction="row" spacing={4}>
                                  <Box sx={{ height: '93px', width: '37px' }}>
                                    {!!itemUrls.get(cartItem.id) && (
                                      <img
                                        height="93px"
                                        width="37px"
                                        src={itemUrls.get(cartItem.id)}
                                        alt={'cart-item'}
                                      />
                                    )}
                                    {!itemUrls.get(cartItem.id) && <CircularProgress />}
                                  </Box>
                                  <Stack direction="column">
                                    <Typography
                                      align="left"
                                      gutterBottom
                                      variant="h6"
                                      component="div"
                                    >
                                      {cartItem.product.name}
                                    </Typography>
                                    <Typography align="left" variant="body2" color="text.secondary">
                                      {cartItem.product.description}
                                    </Typography>
                                    <Typography align="left" variant="body2" color="text.secondary">
                                      Quantity: {cartItem.quantity}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </CardContent>
                            </Card>
                          </Box>
                        );
                      })}
                  </Card>
                </>
              );
            })}
        </Box>
      </Paper>
    </>
  );
};
