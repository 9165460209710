import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ReactElement } from 'react';
import { CenteredProgress } from './CenteredProgress';

interface PrivateRouteProps {
  component: () => ReactElement;
}

export const PrivateRoute = ({ component }: PrivateRouteProps) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <CenteredProgress />,
  });

  return <Component />;
};
