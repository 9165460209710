import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { Elements, PaymentElement, useElements } from '@stripe/react-stripe-js';
import { useMemo, useState, useContext } from 'react';
import currency from 'currency.js';
import { CenteredProgress } from '../basic/CenteredProgress';
import { UserData, UserDataContext } from '../../context/UserData';
import { getStripe } from '../../utils/getStripe';
import { Snack, SnackbarContext } from '../../context/SnackbarContext';

interface PaymentFormProps {
  handleBack: () => void;
}

export const PaymentForm = ({ handleBack }: PaymentFormProps) => {
  const { cart } = useContext(UserData) as UserDataContext;
  const secret = cart.clientSecret;

  const options = {
    clientSecret: useMemo(() => secret, [secret]),
  };

  return (
    <>
      {secret && (
        <Elements options={options} stripe={getStripe()}>
          <StripePaymentForm secret={secret} handleBack={handleBack}></StripePaymentForm>
        </Elements>
      )}
    </>
  );
};

interface StripePaymentFormProps {
  secret: string | undefined;
  handleBack: () => void;
}
const StripePaymentForm = ({ secret, handleBack }: StripePaymentFormProps) => {
  const elements = useElements();
  const [cardComplete, setCardComplete] = useState(false);
  const [busy, setBusy] = useState(false);
  const { setSnack } = useContext(SnackbarContext);
  const { cart } = useContext(UserData) as UserDataContext;

  const handleSubmit = async (e: any) => {
    setBusy(true);
    e.preventDefault();

    const stripe = await getStripe();
    if (!stripe || !elements) {
      return <CircularProgress />;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_CART_REDIRECT as string,
      },
    });

    if (result.error) {
      const message = `${result.error.message}. Please correct and re-submit`;
      setSnack(
        new Snack({
          message,
          color: 'error',
          open: true,
        })
      );
    }
  };

  return (
    <>
      <Box>
        <Typography mt="20px" ml="12px" mb="12px" variant="h5" align="left">
          Payment
        </Typography>
        <Typography align="left" ml="12px" variant="h6">
          Total with sales tax: {currency(cart.totalCost || 0).format()}
        </Typography>
      </Box>

      {!secret && <CenteredProgress />}
      {secret && (
        <>
          <form onSubmit={handleSubmit}>
            <Box m="12px">
              <PaymentElement
                onChange={(event) => {
                  setCardComplete(event.complete);
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button variant="outlined" color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Box
                component="img"
                sx={{
                  height: 35,
                  width: 151,
                }}
                alt="PoweredByStripe"
                src="stripe.svg"
              />
              <Box sx={{ flex: '1 1 auto' }} />

              {!busy && (
                <Button variant="outlined" disabled={!cardComplete} type="submit">
                  Place Order
                </Button>
              )}
              {busy && <CircularProgress />}
            </Box>
          </form>
        </>
      )}
    </>
  );
};
