import { createContext, useEffect, useState } from 'react';
import { Template } from 'canvas-common';
import API from '../API';

type TemplateDefaultValue = {
  templates: Template[];
  getTemplate: (templateId: string) => Template | undefined;
};

export const TemplateData = createContext<TemplateDefaultValue>({
  templates: [],
  getTemplate: () => {
    return undefined;
  },
});

const TemplateDataProvider = ({ children }: any) => {
  const apiClient = API(undefined);

  const [templates, setTemplates] = useState<Template[]>([]);

  useEffect(() => {
    const intializeTemplates = async () => {
      const beTemplates = await apiClient.getTemplates();
      setTemplates(beTemplates);
    };

    intializeTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTemplate = (templateId: string) => {
    return templates.find((t) => t.templateId === templateId);
  };

  const value = {
    templates,
    getTemplate,
  };

  return <TemplateData.Provider value={value}>{children}</TemplateData.Provider>;
};

export default TemplateDataProvider;
