import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useState } from 'react';
import { Box, CircularProgress, Paper, Stack } from '@mui/material';
import { Product, Template } from 'canvas-common';
import { TemplateData } from '../context/TemplateData';
import { PersonalizeDialog } from '../components/PersonalizeDialog';
import { ProductData } from '../context/ProductData';
import { TemplateStructuredData } from '../components/basic/TemplateStructuredData';
import { TemplateCard } from '../components/cards/TemplateCard';

export const TemplateView = () => {
  const [personalize, setPersonalized] = useState(false);
  const { templateId } = useParams();
  const { getTemplate }: any = useContext(TemplateData);
  const template: Template = getTemplate(templateId);
  const navigate = useNavigate();
  const { getProduct, productsLoading } = useContext(ProductData);

  const handlePersonalizeClosed = () => {
    navigate('/');
  };

  if (!template || productsLoading) {
    return <CircularProgress />;
  }

  if (personalize) {
    return <PersonalizeDialog template={template} onClose={handlePersonalizeClosed} />;
  }

  const product = getProduct('24_POLY') as Product;
  return (
    <>
      <TemplateStructuredData template={template} />
      <Stack direction="row">
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />

        <Paper elevation={4}>
          <TemplateCard template={template} product={product} setPersonalized={setPersonalized} />
        </Paper>

        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />
      </Stack>
    </>
  );
};
