import { Box, IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ClostButtonProps {
  onClose: () => void;
}

export const CloseButton = ({ onClose }: ClostButtonProps) => {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 1 }}>
        <Box sx={{ flex: '1 1 auto' }} />
        <Tooltip title="Close">
          <IconButton onClick={onClose} size="large" color="primary" aria-label="cancel">
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
};
