"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonalizedImage = exports.PercentCrop = void 0;
var PercentCrop = /** @class */ (function () {
    function PercentCrop(crop) {
        this.unit = '%';
        Object.assign(this, crop);
    }
    return PercentCrop;
}());
exports.PercentCrop = PercentCrop;
var PersonalizedImage = /** @class */ (function () {
    function PersonalizedImage() {
    }
    return PersonalizedImage;
}());
exports.PersonalizedImage = PersonalizedImage;
