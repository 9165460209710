import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';

import currency from 'currency.js';
import { CartItem } from 'canvas-common';
import { UserData, UserDataContext } from '../../context/UserData';
import API from '../../API';

interface CartDisplayProps {
  handleNext: () => void;
}

export const CartDisplay = ({ handleNext }: CartDisplayProps) => {
  const { cart, syncCartItems } = useContext(UserData) as UserDataContext;
  const [itemUrls, setItemUrls] = useState<Map<string, string>>(new Map());

  const apiClient = API(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    const renderCartItems = async () => {
      Promise.all(
        cart.cartItems.map(async (ci: CartItem) => {
          const body = {
            templateId: ci.personalizedTemplate.template.templateId,
            images: ci.personalizedTemplate.images,
          };
          const data = await apiClient.renderTemplate(body);
          setItemUrls(new Map(itemUrls.set(ci.id, data)));
        })
      );
    };

    renderCartItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  const handleDecrement = (id: string) => {
    const theCart = [...cart.cartItems];
    const cartItem = theCart.find((item) => item.id === id);
    if (cartItem) {
      cartItem.quantity -= 1;
      syncCartItems({
        cart: { ...cart, cartItems: [...theCart] },
        createOrUpdatePayment: false,
        calculateTax: false,
      });
    }
  };

  const handleIncrement = (id: string) => {
    const theCart = [...cart.cartItems];
    const cartItem = theCart.find((item) => item.id === id);
    if (cartItem) {
      cartItem.quantity += 1;
      syncCartItems({
        cart: { ...cart, cartItems: [...theCart] },
        createOrUpdatePayment: false,
        calculateTax: false,
      });
    }
  };

  const handleRemove = (id: string) => {
    const theCart = [...cart.cartItems];
    const cartItem = theCart.find((item) => item.id === id);
    if (cartItem) {
      const index = theCart.indexOf(cartItem);
      theCart.splice(index, 1);
      syncCartItems({
        cart: { ...cart, cartItems: [...theCart] },
        createOrUpdatePayment: false,
        calculateTax: false,
      });
    }
  };

  return (
    <>
      <Box>
        <Typography mt="20px" ml="12px" mb="12px" variant="h5" align="left">
          Shopping Cart
        </Typography>
      </Box>

      {cart.cartItems.length === 0 && (
        <Card variant="outlined" sx={{ margin: '10px', minHeight: '200px' }}>
          <Typography variant="h5" sx={{ mt: '40px' }}>
            Your cart is empty
          </Typography>
          <Box mt="20px">
            <Button onClick={() => navigate('/create')} variant="contained">
              Create a custom banner..
            </Button>
          </Box>
        </Card>
      )}
      {cart.cartItems.map((cartItem: any) => {
        return (
          <Box key={cartItem.id}>
            <Card variant="outlined" sx={{ margin: '10px' }}>
              <CardContent>
                <Stack direction="row" spacing={4}>
                  <Box sx={{ height: '187px', width: '75px' }}>
                    {!!itemUrls.get(cartItem.id) && (
                      <img
                        height="187px"
                        width="75px"
                        src={itemUrls.get(cartItem.id)}
                        alt={'cart-item'}
                        loading="lazy"
                      />
                    )}
                    {!itemUrls.get(cartItem.id) && <CircularProgress />}
                  </Box>
                  <Stack direction="column">
                    <Typography align="left" gutterBottom variant="h6" component="div">
                      {cartItem.product.name}
                    </Typography>
                    <Typography align="left" variant="body2" color="text.secondary">
                      {cartItem.product.description}
                    </Typography>
                    <Typography
                      align="left"
                      variant="body2"
                      color="text.secondary"
                      sx={{ marginBottom: '10px' }}
                    >
                      Each: $ {cartItem.product.price}
                    </Typography>
                    <Typography align="left" variant="body2" color="text.secondary">
                      Quantity:
                    </Typography>

                    <ButtonGroup
                      size="small"
                      aria-label="small outlined button group"
                      sx={{ marginBottom: '10px' }}
                    >
                      {cartItem.quantity === 0 && (
                        <Button onClick={() => handleRemove(cartItem.id)}>Remove</Button>
                      )}
                      {cartItem.quantity > 0 && (
                        <Button onClick={() => handleDecrement(cartItem.id)}>-</Button>
                      )}
                      <Button
                        disabled
                        sx={{
                          '&.Mui-disabled': {
                            color: '#000',
                          },
                        }}
                      >
                        {cartItem.quantity}
                      </Button>
                      <Button onClick={() => handleIncrement(cartItem.id)}>+</Button>
                    </ButtonGroup>
                    <Typography
                      align="left"
                      variant="body1"
                      color="text.secondary"
                      sx={{ marginBottom: '10px' }}
                    >
                      Subtotal:{' '}
                      {currency(cartItem.product.price).multiply(cartItem.quantity).format()}
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Box>
        );
      })}
      {cart.cartItems.length > 0 && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Typography>Shipping: {currency(cart.shippingCost || 0).format()}</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Typography variant="h6" sx={{ fontWeight: '700' }}>
              Total (not including sales tax): {currency(cart.totalCost || 0).format()}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Typography>Sales tax will be calculated during checkout</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button variant="outlined" onClick={handleNext}>
              Checkout
            </Button>
          </Box>
        </>
      )}
    </>
  );
};
