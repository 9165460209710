import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

export const AuthProvider = ({ children }: any) => {
  const navigate = useNavigate();

  const onRedirectingCallback = async (appState: any) => {
    const destination = appState?.returnTo || '/login';
    navigate(destination);
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/login`,
        audience: 'https://canvaspaints-admin.canvaspaints.com',
      }}
      onRedirectCallback={onRedirectingCallback}
    >
      {children}
    </Auth0Provider>
  );
};
