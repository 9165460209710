import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const Footer = () => {
  return (
    <>
      <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton color="inherit">
            <SearchIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
};
