import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Input,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState, useContext } from 'react';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CloseIcon from '@mui/icons-material/Close';
import { v4 } from 'uuid';
import axios from 'axios';
import {
  CartItem,
  PercentCrop,
  PersonalizedImage,
  PersonalizedTemplate,
  Product,
  Template,
} from 'canvas-common';
import API from '../API';
import { PhotoCropDialog } from './PhotoCropDialog';
import { UserData, UserDataContext } from '../context/UserData';
import { ProductData } from '../context/ProductData';
import { Snack, SnackbarContext } from '../context/SnackbarContext';

interface PersonalizedDialogProps {
  template: Template;
  onClose: () => void;
}

export const PersonalizeDialog = ({ template, onClose }: PersonalizedDialogProps) => {
  const [uploadOpen, setUploadOpen] = useState(false);
  const [templateImage, setTemplateImage] = useState<string | undefined>(undefined);
  const [files, setFiles] = useState(new Array(template.images.length));
  const [cropFile, setCropFile] = useState<File | undefined>(undefined);
  const [cropIndex, setCropIndex] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scale, setScale] = useState<number>(0.5); // allow zoom in future
  const { setSnack } = useContext(SnackbarContext);
  const [images, setImages] = useState<PersonalizedImage[]>(
    template.images.map((img) => {
      return { rect: img.rect, index: img.index };
    })
  );

  const { getProduct }: any = useContext(ProductData);
  const { cart, syncCartItems } = useContext(UserData) as UserDataContext;

  const apiClient = API(undefined);

  useEffect(() => {
    const initialRender = async () => {
      const body = { templateId: template.templateId, images: [] };
      const data = await apiClient.renderTemplate(body);
      setTemplateImage(data);
    };

    initialRender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddToCart = () => {
    const product: Product = getProduct('24_POLY');
    const finalImages = images.map((img) => {
      const templateImg = template.images.filter((i) => i.index === img.index)[0];
      const productionRect = templateImg.productionRects.filter(
        (pr) => pr.productWidth === product.width
      )[0].rect;
      return { ...img, productionRect };
    });
    const personalizedTemplate: PersonalizedTemplate = {
      template,
      images: finalImages,
    };
    const cartItem: CartItem = {
      id: v4(),
      product,
      personalizedTemplate,
      quantity: 1,
    };
    syncCartItems({
      cart: { ...cart, cartItems: [...cart.cartItems, cartItem] },
      createOrUpdatePayment: false,
      calculateTax: false,
    });
    setSnack(
      new Snack({
        message: 'Nice! The banner has been added to your shopping cart.',
        color: 'success',
        open: true,
      })
    );
    onClose();
  };

  const handleCropComplete = (file: File, crop: PercentCrop | undefined, index: number) => {
    const newImages = [...images];
    newImages[index].crop = crop;
    setImages(newImages);
    const newFiles = [...files];
    newFiles[index] = file;
    setFiles(newFiles);
    setUploadOpen(false);
    uploadPhoto(file, index);
  };

  const uploadPhoto = async (file: File, index: number) => {
    if (file) {
      try {
        setTemplateImage(undefined);
        const uuid = v4();
        // Get signed S3 bucket URL from backend
        const { url: s3BucketUrl, fields } = await apiClient.getS3BucketUploadUrl(
          cart.userUUID as string,
          uuid
        );

        // Upload image to S3 bucket
        const form = new FormData();
        Object.entries(fields).forEach(([field, val]) => form.append(field, val as string));
        form.append('Content-Type', file.type);
        form.append('file', file);
        await axios.post(s3BucketUrl, form);

        const newImages = [...images];
        newImages[index].cartS3Key = fields.key;
        setImages(newImages);

        const newS3image = await apiClient.cropAndResize(newImages[index]);
        const new2Images = [...images];
        new2Images[index].cartResizedS3Key = newS3image;
        setImages(new2Images);

        // Get the rendered template
        const body = { templateId: template.templateId, images: new2Images };
        const data = await apiClient.renderTemplate(body);
        setTemplateImage(data);
        setSnack(
          new Snack({
            message: 'Image uploaded.',
            color: 'success',
            open: true,
          })
        );
      } catch (err) {
        setSnack(
          new Snack({
            message: 'Oops! We had an error when uploading this image. Please try again.',
            color: 'error',
            open: true,
          })
        );
        console.error(err);
      } finally {
        // Set template image to an error image
      }
    }
  };

  const cropPhoto = (event: ChangeEvent<HTMLInputElement>) => {
    const file: File | undefined = event.target.files?.[0];
    if (file) {
      setCropFile(file);
      setUploadOpen(true);
    }
  };

  const editExisting = (index: number) => {
    setCropIndex(index);
    setUploadOpen(true);
  };

  const complete = () => {
    const completed = files.filter((file) => file);
    return completed.length === images.length;
  };

  return (
    <>
      {!uploadOpen && (
        <Stack direction="row">
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />
          <Paper elevation={4} sx={{ minWidth: 550 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Typography variant="h5" gutterBottom sx={{ ml: '20px', mt: '10px' }}>
                {template.title}
              </Typography>
              <Box sx={{ flex: '1 1 auto' }} />

              <Box>
                <Tooltip title={complete() ? 'Add To Cart' : 'Upload all photos to add to cart'}>
                  <span>
                    <IconButton
                      onClick={handleAddToCart}
                      disabled={!complete()}
                      size="large"
                      color="primary"
                      aria-label="add to shopping cart"
                    >
                      <AddShoppingCartIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Close">
                  <IconButton onClick={onClose} size="large" color="primary" aria-label="cancel">
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Stack direction="row">
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />
              <Box mb={4} sx={{ position: 'relative', height: '750px', width: '300px' }}>
                {!!templateImage && (
                  <img
                    width="300px"
                    height="750px"
                    src={templateImage}
                    alt={'template-personalization'}
                  />
                )}
                {!templateImage && (
                  <CircularProgress sx={{ position: 'absolute', top: '50%' }}></CircularProgress>
                )}
                {!!templateImage &&
                  template.images?.map((image) => {
                    return (
                      <div key={image.index}>
                        {!files[image.index] && (
                          <>
                            <Input
                              sx={{ display: 'none' }}
                              id="contained-button-file"
                              onChange={(e) => cropPhoto(e as ChangeEvent<HTMLInputElement>)}
                              type="file"
                              inputProps={{ accept: 'image/jpeg, image/jpg' }}
                            />

                            <label htmlFor="contained-button-file">
                              <Button
                                component="span"
                                onClick={() => setCropIndex(image.index)}
                                sx={{
                                  color: '#fff',
                                  position: 'absolute',
                                  top: `${Math.round(image.rect.top * scale)}px`,
                                  right: `${Math.round(
                                    image.rect.right * scale - image.rect.width * scale
                                  )}px`,
                                  width: `${Math.round(image.rect.width * scale)}px`,
                                  height: `${Math.round(image.rect.height * scale)}px`,
                                  '&:hover': {
                                    backgroundColor: '#e7a614',
                                    color: '#000',
                                  },
                                }}
                              >
                                Upload Photo...
                              </Button>
                            </label>
                          </>
                        )}

                        {files[image.index] && (
                          <>
                            <Button
                              component="span"
                              onClick={() => editExisting(image.index)}
                              sx={{
                                color: '#000',
                                position: 'absolute',
                                top: `${Math.round(image.rect.top * scale)}px`,
                                right: `${Math.round(
                                  image.rect.right * scale - image.rect.width * scale
                                )}px`,
                                width: `${Math.round(image.rect.width * scale)}px`,
                                height: `${Math.round(image.rect.height * scale)}px`,
                                '&:hover': {
                                  backgroundColor: '#e7a614',
                                },
                                '&:hover:before': {
                                  content: `${'"Update Photo..."'}`,
                                },
                              }}
                            ></Button>
                          </>
                        )}
                      </div>
                    );
                  })}
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />
            </Stack>
          </Paper>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} />
        </Stack>
      )}

      {uploadOpen && cropFile && (
        <PhotoCropDialog
          file={cropFile}
          rect={template.images[cropIndex].rect}
          origCrop={
            images[cropIndex]?.crop ? new PercentCrop({ ...images[cropIndex]?.crop }) : undefined
          }
          handleCropCanceled={() => setUploadOpen(false)}
          handleCropComplete={handleCropComplete}
          index={cropIndex}
        />
      )}
    </>
  );
};
